<template>
  <div class="question">
    <div class="item item_index">
      az好货通为您提供了在线受理退货退款服务，您只需要登录您的个人账户，在“我的订单”页面中针对商品在线提交申请信息即可。
    </div>
    <div class="item item_index">符合退款政策的商品，您可以申请退货退款。</div>
    <div class="item item_index">退货退款操作流程如下：</div>
    <div class="item">
      1.在订单列表里选择需要申请退货退款的商品，点击申请售后；
    </div>
    <div class="item">
      2.如果同时需要退货，请选择“退货退款”。填写退款金额及退款原因，并尽量提供相关凭证图片，点击提交申请。最大可退金额为您的实付金额；
    </div>
    <div class="item">
      3.退款申请提交后，需要等待商家处理。商家同意退款后，需要您将退货商品寄回商家所提供的退货地址，并填写物流公司及物流单号；如果商家拒绝退款申请，请修改申请或与在线客服取得联系沟通退款申请；
    </div>
    <div class="item">
      4.退货物流信息提交后，请等待商家确认收货。商家确认收货后，系统后续会自动发起退款；如果商家拒绝退款申请，请修改申请或与在线客服取得联系沟通退款申请。退款金额会在三到七个工作日内退回您的账户。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 15px;
  border-top: 1px solid #eee;
}
.item {
  padding-top: 10px;
}
.item_index {
  text-indent: 25px;
}
</style>
