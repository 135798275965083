<template>
  <div class="question">
    <div class="item item_index">
      您的订单从海外仓寄出，自仓库收到您的订单至订单派送到您手中，预计7-15个工作日即可到货。
    </div>
    <div class="item_title item_top">注意事项：</div>
    <div class="item">1、暂时不支持港澳台地区的派送</div>
    <div class="item">
      2、如遇双十一等大型活动期间、订单需要实名认证等特殊情况，或者遇台风、暴雨、大雾等不可抗力的原因下，订单到达时间将根据具体到达情况而定。
    </div>
    <div class="item item_top item_index">
      az好货通提供的订单物流跟踪功能，所查询的信息均来源于合作快递公司官网，部分官网信息由于同步不及时，有可能出现更新延迟现象，为您带来的不便，还敬请谅解。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 15px;
  border-top: 1px solid #eee;
}
.item {
  padding-top: 10px;
}
.item_index {
  text-indent: 25px;
}
.item_title {
  font-size: 14px;
  font-weight: 500;
  color: rgba(34, 34, 34, 1);
}
.item_top {
  margin-top: 30px;
}
</style>
