<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/question/detail/components/TiXianQuestion.vue
 * @Author: 张兴业
 * @Date: 2021-05-27 17:39:03
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-05-27 17:56:38
-->
<template>
  <div class="question">
    <div class="title">AZG门店收益&提现常见问题FAQ（试运营阶段）</div>
    <div class="item item_q">Q：门店收益都有哪些？</div>
    <div class="item">
      A：经销商的收益主要分为返利（商品正常售卖后的利润）和返点（平台营销活动的定向激励），返利是跟随订单同步进行，返点针对不同的营销活动有对应的活动时间，以具体活动细则为准。
    </div>
    <div class="item item_q">
      Q：门店返利时效是多久？最低可提现金额是多少？
    </div>
    <div class="item">
      A：门店的返利在订单支付成功后即可进入到店主的可提现账户中，在消费者确认收货的14个自然日后即可提现（下图：好货通APP-我的账户-提现），最低提现金额为10元：
    </div>
    <div class="item">
      <img src="@/assets/img/questions/pic1.png" alt="" style="width: 100%;" />
    </div>
    <div class="item item_q">Q：门店的返点时效是多久？返点方式是什么？</div>
    <div class="item">
      A：门店的返点会在活动结束后的15个自然日，以银行打款的方式打到门店在APP绑定的银行账户（下图：需要门店提前在好货通APP的我的账户进行银行卡绑定并完成电签）
    </div>
    <div class="item">
      <img src="@/assets/img/questions/pic2.png" alt="" style="width: 100%;" />
    </div>
    <div class="bottom">
      本文档为试运营阶段关于收益与提现的政策解读，如有更改，AZG将提前30个自然日通知到门店用户
      本文档最终解释权归AZG所有
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 10px 15px 15px;
  border-top: 1px solid #eee;
}

.title {
  color: #000;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  margin-bottom: 16px;
}

.item_q {
  color: #cfb48c;
  font-size: 14px;
  font-weight: 500;
}

.item {
  padding-top: 5px;
  font-size: 13px;
  /* text-indent: 25px; */
}
.item_blod {
  font-weight: 500;
}

.bottom {
  font-size: 12px;
  text-align: center;
  text-align: justify;
  margin-top: 32px;
}
</style>
