<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/question/detail/components/Overseas.vue
 * @Author: 张兴业
 * @Date: 2020-11-09 10:22:40
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-05-12 13:29:35
-->
<template>
  <div class="question">
    <div class="item">
      az好货通承诺其销售的所有商品是<span class="item_blod"
        >“海外正品保障，海外直供”</span
      >，为您创造一个便捷的跨境购物体验，足不出户轻松扫遍澳新，让您省心更放心！
    </div>
    <div class="item">
      az好货通已与多家澳新一线品牌及行业巨头达成战略合作关系，同时将会有更多知名平台入驻az好货通，业务涉及澳新乃至全球多个国家和地区，SKU丰富，品牌商授权直供，全程技术保障，从源头为消费者提供高品质的服务。
    </div>
    <div class="item">
      az好货通销售的海外商品均为优质海外商品，消费者下单后，<span
        class="item_blod"
        >商品从澳新地区通过国际物流方式发出或从符合国家法律规定的中国保税区发出，且有物流公司提供的从商家发货地到消费者目的地之间完整物流状态信息。</span
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 15px;
  border-top: 1px solid #eee;
}
.item {
  padding-top: 10px;
  text-indent: 25px;
}
.item_blod {
  font-weight: 500;
}
</style>
