<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/question/detail/components/BreakDown.vue
 * @Author: 张兴业
 * @Date: 2020-11-09 10:22:40
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-05-12 13:29:42
-->
<template>
  <div class="question">
    <div class="item">
      破损包赔服务是指消费者在az好货通购买商品时，若消费者在收货时存在包装/商品破损、漏液的情形，并在签收之时起的72小时发起售后申请，且在消费者提供有效凭证的情况下，az好货通有对破损商品对应金额做退款处理的服务。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  padding: 15px;
  border-top: 1px solid #eee;
}
.item {
  text-indent: 25px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
}
</style>
