<template>
  <div class="question">
    <div class="title_bor">温馨提示：</div>
    <div class="item item_bor">
      为了高效解决您的问题，减少您可能的等待时间，建议您仔细阅读下面的常见问题：查询物流状态、退货退款等操作，均可登入我的订单页进行自助查看，或联系在线客服进行咨询。若仍无法解决您的问题，建议您在此页面下拉最下方，联系在线客服或拨打客服热线为您服务。感谢您对az好货通的支持！
    </div>
    <div>
      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="(item, index) in list"
          :key="index"
          :name="index"
        >
          <div slot="title" class="que_title">
            <img :src="item.pic" class="que_img" />
            <span class="pad">{{ item.title }}</span>
          </div>
          <div class="item_con">{{ item.con }}</div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="det_det">
      如未能解决您的问题，请联系在线客服（工作时间：周一到周五9:00-18:00）
    </div>
    <!-- <div class="bottom_service" @click="goService">
      <img src="@/assets/order/icon_service@2x.png" alt="" />
      <span class="ser_font">联系客服</span>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "拍下的订单之后什么时候发货，一般多久可以收到货？",
          pic: require("@/assets/mine/inform/q1.png"),
          con:
            "正常情况下您的订单将在120小时内发出（除发货地法定节假日及特殊情况外）。发货之后，您可以在我的订单中可以查看发货状态。由于快递公司可能无法实时更新信息，因此您看到的发货情况可能存在一定延迟。"
        },
        {
          title: "为什么订单显示发货了，但是快递信息还没有更新？",
          pic: require("@/assets/mine/inform/q2.png"),
          con:
            "如果您查看物流跟踪信息一直停留在同一个状态，可能是物流公司未及时录入新信息或物流公司更新后同步到az好货通存在滞后情况，不会影响正常收货，您也可以先联系在线客服或对应的物流公司，确认货物运送是否出现异常。"
        },
        {
          title: "商品是否有中文标签？",
          pic: require("@/assets/mine/inform/q3.png"),
          con: "az好货通所售商品均为一般贸易商品，均有中文标签。"
        }
      ],
      activeNames: ["0"]
    };
  },
  methods: {
    backClick() {
      this.$router.back();
    },
    goService() {
      // this.$router.push('/mine/message')
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  line-height: 22px;
  padding: 15px 0;
  border-top: 1px solid #eee;
}
.item {
  padding: 0 15px 10px;
  text-indent: 25px;
}
.title_bor {
  padding: 0 15px 10px;
}
.item_bor {
  padding-bottom: 20px;
  /* border-bottom: 1px solid #eee; */
}
.item_blod {
  font-weight: 500;
}
.que_img {
  width: 25px;
}
.que_title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
}
.pad {
  margin-left: 15px;
  display: inline-block;
  width: 80%;
}
.item_con {
  padding-left: 45px;
  font-size: 14px;
  color: #666;
}
.det_det {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 22px;
  padding: 20px 10px 0;
  /* background: #f2f2f2; */
}
.bottom_service {
  font-size: 18px;
  font-weight: 400;
  color: rgba(221, 145, 50, 1);
  line-height: 52px;
  margin: 0 auto;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.bottom_service img {
  width: 24px;
  margin-top: 15px;
  vertical-align: middle;
}
.ser_font {
  vertical-align: middle;
}
</style>
