<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/question/detail/components/Notification.vue
 * @Author: 张兴业
 * @Date: 2020-11-09 10:22:40
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-05-12 13:35:41
-->
<template>
  <div class="question">
    <div class="item">尊敬的客户：</div>
    <div class="item">您好！</div>
    <div class="item">
      为帮助您更好地选购az好货通商品，请您在购买前务必认真、详细阅读并完全理解本告知书的全部内容，并对自身风险承担做出客观判断。同意本告知书内容后再下单购买：
    </div>
    <div class="item">
      1、<span class="item_title"
        >az好货通声明，所售商品由az好货通提供，商品品质均符合原产地相关标准，由az好货通承担商品质量安全保证责任。</span
      >
    </div>
    <div class="item">
      2、您在az好货通下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思，您应对收货人的行为及意思表示的法律后果承担连带责任。
    </div>
    <div class="item">
      3、您下单时须填写购买的商品数量、价款及收货人，联系方式、收货地址等内容；付款及订单提交成功前，您有权修改所填信息。付款及订单提交成功后，将视您与az好货通之间就您所购买的商品成立了合同关系，我们会按照您再订单中订购的商品进行发货。
    </div>
    <div class="item">
      4、由于市场变化以及其他难以控制的因素，我们无法避免您提交的订单信息中的商品出现缺货情况，您有权取消订单，我们在取得您同意的前提下可以取消订单，若您已付款，则为您办理退款。
    </div>
    <div class="item">
      5、您在使用az好货通过程中，有任何问题可联系在线客服或拨打客服热线为您服务。感谢您对az好货通的支持！
    </div>
    <!-- <div class="item item_top">az好货通精选澳新好货，为您提供100%正品，具体参见<span class="go" @click="$router.replace('/user/question/detail?key=2&name=正品保障')">“正品保障”</span>。</div> -->
    <div class="item item_top">感谢您的支持与理解！</div>
    <!-- <div class="item">谢谢！</div> -->
    <div class="item">az好货通</div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 15px;
  border-top: 1px solid #eee;
}
.item {
  padding-top: 10px;
}
.item_index {
  text-indent: 25px;
}
.item_title {
  font-size: 14px;
  font-weight: 500;
  color: rgba(34, 34, 34, 1);
}
.item_top {
  margin-top: 30px;
}
.go {
  color: #2976e8;
}
</style>
