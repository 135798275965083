<template>
  <div class="question">
    <div class="item item_index">
      您可以登录您的Myaz.com账户，在我的订单—订单详情页面中查看您订单的实时配送情况。
    </div>
    <div class="item item_index">
      部分快递公司物流信息系统显示会略有延迟，如订单已显示为发货状态，但无物流信息显示，请您稍等约24小时左右，即可查询到。或者您可以直接在页面点击物流公司官网进行实时订单物流状态查询。
    </div>
    <div class="item item_index">
      请您签收货品时，尽量在快递人员在场时当面检查商品（如果快递人员坚持先前签收再验货，可以在签收之后立刻检查）。
    </div>
    <div class="item_title item_top">请注意：</div>
    <div class="item">1.务必检查封箱胶带是否有被拆开重新粘贴过的痕迹。</div>
    <div class="item">2.快递包装是否有破损、渗漏、缺少等现象。</div>
    <div class="item">
      3.若出现以上情况，建议您尽快与我们取得联系，并拍照存档，把照片发给我们的客服人员，以便我们对物流快递公司进行追责。
    </div>
    <div class="item">
      退货运费说明参见“退款说明”。
      <!-- <span
        class="go"
        @click="$router.replace('/user/question/detail?key=8&name=退款说明')"
        >“退款说明”</span
      >。 -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 15px;
  border-top: 1px solid #eee;
}
.item {
  padding-top: 10px;
}
.item_index {
  text-indent: 25px;
}
.item_title {
  font-size: 14px;
  font-weight: 500;
  color: rgba(34, 34, 34, 1);
}
.item_top {
  margin-top: 30px;
}
.go {
  color: #2976e8;
}
</style>
